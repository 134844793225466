import React, { useState } from "react"
import styled from "styled-components"
import News3_1 from "./news3-1"
import Pagination from "@material-ui/lab/Pagination"
const Container = styled.div`
  padding: 0 0 6vw;
  .MuiPagination-ul,
  .MuiPagination-ul:hover .MuiPagination-ul li:hover {
    justify-content: center;
    background-color: transparent;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: transparent;
    color: #d96e62;
    line-height: 1.67;
    text-decoration: underline;
  }

  .show-480 {
    display: none;
  }

  .hide-480b {
    display: block;
  }

  @media screen and (max-width: 480px) {
    .show-480 {
      display: flex;
    }

    .hide-480b,
    .hide-480 {
      display: none;
    }
  }
`

const newspaper3 = ({ lists }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(4)
  const [postsPerPage2, setPostsPerPage2] = useState(6)
  const indexOfLast = currentPage * postsPerPage
  const indexOfFirst = indexOfLast - postsPerPage

  const indexOfLast2 = currentPage * postsPerPage2
  const indexOfFirst2 = indexOfLast2 - postsPerPage2

  function currentPosts(tmp: any) {
    let currentPosts = 0
    currentPosts = tmp.slice(indexOfFirst, indexOfLast)
    return currentPosts
  }

  function currentPosts2(tmp: any) {
    let currentPosts2 = 0
    currentPosts2 = tmp.slice(indexOfFirst2, indexOfLast2)
    return currentPosts2
  }

  const handleChange = (event, value: any) => {
    setCurrentPage(value)
  }

  return (
    <Container>
      <div className="hide-480b">
        <News3_1 lists={currentPosts(lists)} />
        <Pagination
          count={parseInt(lists.length / postsPerPage) + 1}
          onChange={handleChange}
        ></Pagination>
      </div>
      <div className="show-480">
        <News3_1 lists={currentPosts2(lists)} />
      </div>
      <Pagination
        className="show-480"
        style={{ justifyContent: "center" }}
        count={parseInt(lists.length / postsPerPage2) + 1}
        onChange={handleChange}
      ></Pagination>
    </Container>
  )
}

export default newspaper3
