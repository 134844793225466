import React from "react"
import styled from "styled-components"
import newspaper from "../../static/news/newsback.png"
import newspaper_480 from "../../static/news/newsback-480.png"
const Container = styled.div`
  .news-background img {
    width: 100vw;
  }
  .news-background p {
    font-size: 2.3vw;
    position: absolute;
    font-weight: 300;
    width: 60vw;
    left: 50vw;
    margin-left: -30vw;
    margin-top: 7vw;
  }

  .news-background p span {
    font-weight: bold;
  }

  .show-480 {
    display: none;
  }
  .hide-480 {
    display: block;
  }

  @media screen and (max-width: 480px) {
    .show-480 {
      display: block;
    }
    .hide-480 {
      display: none;
    }

    .news-background p {
      font-size: 7vw;
      left: 50vw;
      margin-left: -40vw;
      margin-top: 10vw;
    }
  }
`
const newspaper1 = () => {
  return (
    <Container>
      <div className="news-background">
        <p>
          <span>언론 속</span> 공간샘
        </p>
        <img className="hide-480" src={newspaper} />
        <img className="show-480" src={newspaper_480} />
      </div>
    </Container>
  )
}

export default newspaper1
