import React, { useState } from "react"
import styled from "styled-components"

const Container = styled.div`
  padding: 0 0 6vw;
  .news-list {
    display: flex;
    width: 60vw;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    padding: 2vw;
  }

  .news-image img {
    width: 18vw;
    height: 12vw;
  }

  .news-text-part {
    width: 32vw;
  }

  .news-text-title {
    font-size: 1.3vw;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25;
  }

  .news-text-write {
    font-size: 1vw;
    font-weight: bold;
    margin: 0.7vw 0;
  }

  .news-text-contents {
    font-size: 0.9vw;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5;
  }

  .news-text-url {
    font-size: 1vw;
    font-weight: bold;
    margin-top: 1vw;
    cursor: pointer;
  }

  hr {
    width: 53vw;
    margin: 0 auto;
  }

  .show-480 {
    display: none;
  }

  .hide-480 {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    .show-480 {
      display: flex;
    }

    .hide-480 {
      display: none;
    }

    .news-list {
      width: 100vw;
      padding: 3vw 10vw;
    }

    .news-image img {
      width: 25vw;
      height: 20vw;
    }

    .news-image {
      width: 25vw;
      height: 20vw;
    }

    .news-text-title {
      font-size: 3.5vw;
      white-space: normal;
      overflow: auto;
      font-weight: normal;
      line-height: 1.25;
    }

    .news-text-part {
      width: 44vw;
      display: flex;
      flex-direction: column;
      height: 18vw;
      justify-content: space-between;
    }

    .news-text-write {
      font-size: 2.5vw;
      font-weight: normal;
      color: #555454;
      margin: 0;
    }

    hr {
      width: 100vw;
    }
  }
`

const newspaper3_1 = ({ lists }) => {
  return (
    <Container>
      {lists.map((newslist: any, index: number) => (
        <div>
          <div className="news-list hide-480" key={index}>
            <div className="news-image">
              <img src={newslist.image} />
            </div>
            <div className="news-text-part">
              <p className="news-text-title">{newslist.title}</p>
              <p className="news-text-write">{newslist.writedate}</p>
              <p className="news-text-contents">{newslist.contents}</p>
              <p
                className="news-text-url"
                onClick={() => window.open(`${newslist.url}`, "_blank")}
              >
                자세히 보기 &gt;
              </p>
            </div>
          </div>
          <div className="news-list show-480">
            <div className="news-image">
              <img src={newslist.image} />
            </div>
            <div className="news-text-part">
              <p className="news-text-title">{newslist.title}</p>
              <p className="news-text-write">{newslist.writedate}</p>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </Container>
  )
}

export default newspaper3_1
