import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
const Container = styled.div`
  background-color: #e8e8e8;
  position: relative;
  margin-top: -13vw;
  padding-bottom: 2vw;

  .news-slide-contents {
    display: flex !important;
    width: 55vw !important;
    margin: 0 auto;
    padding: 2vw 0;
    justify-content: space-between;
    align-items: center;
  }
  .news-slide-image-contents {
    width: 20vw;
    height: 15vw;
    overflow: hidden;
    display: flex;
  }
  .news-slide-image-contents img {
    width: 20vw;
    vertical-align: middle;
  }

  .news-slide-text-part {
    width: 30vw;
  }

  .news-slide-title {
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 2vw;
    line-height: 1.25;
  }

  .news-slide-content {
    font-size: 1vw;
    line-height: 1.5;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .slick-dots {
    bottom: -1vw;
  }

  .slick-dots li.slick-active button:before {
    color: #555454;
  }

  .slick-dots li button:before,
  .slick-dots li button {
    color: #fff;
    opacity: 1;
  }

  .slick-dots li {
    margin 0 2px;
  }

  @media screen and (max-width: 480px) {
    background-color: transparent;
    margin-top: 0;
    padding-bottom: 0;
    .hide-480 {
      display: none;
    }
  }
`

const newspaper2 = ({ lists }) => {
  lists.length = 5
  console.log(lists)

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  }

  return (
    <Container>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Slider {...settings} className="hide-480">
        {lists.map((list: any, index: number) => (
          <div className="news-slide-contents" key={index}>
            <div className="news-slide-image-contents">
              <img
                src={list.image}
                onClick={() => window.open(`${list.url}`, "_blank")}
              />
            </div>
            <div className="news-slide-text-part">
              <p className="news-slide-title">{list.title}</p>
              <p className="news-slide-content">{list.contents}</p>
            </div>
          </div>
        ))}
      </Slider>
    </Container>
  )
}

export default newspaper2
