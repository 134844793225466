import React from "react"
import Layout from "../components/Layout/Layout"
import News1 from "../components/news/news1"
import News2 from "../components/news/news2"
import News3 from "../components/news/news3"
import styled from "styled-components"
import { graphql } from "gatsby"

const Container = styled.div`
  overflow-x: hidden;
`
const newsPage = ({ data }) => {
  const {
    allStrapiPaperlists: { nodes: lists },
  } = data

  return (
    <Container>
      <Layout>
        <News1 />
        <News2 lists={lists} />
        <News3 lists={lists} />
      </Layout>
    </Container>
  )
}

export const query = graphql`
  {
    allStrapiPaperlists(sort: { fields: writedate, order: DESC }) {
      nodes {
        title
        contents
        image
        writedate
        url
      }
    }
  }
`

export default newsPage
